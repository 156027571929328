import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Content, WrapperDarkGreen } from "../components/section-styling"

const browser = typeof window !== "undefined" && window

const NotFoundPage = () => {
  return (
    browser && (
      <Layout>
        <Seo title="404: Not found" />

        <WrapperDarkGreen>
          <Content>
            <div>
              <h1>404: Not Found</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
          </Content>
        </WrapperDarkGreen>
      </Layout>
    )
  )
}

export default NotFoundPage
